const CONFIG = {
  headers: [
    { field: 'style', name: 'Type' },
    { field: 'groupBy', name: 'GroupBy' },
    { field: 'timezone', name: 'Timezone' },
    { field: 'addresses', name: 'Emails' },
  ],
};

export default CONFIG;
