<template>
  <div :id="id" class="flex flex-col items-start mb-4 sm:mb-0 w-full">
    <label class="text-gary-700 text-left text-xs sun-label mb-1">You must separate by comma (,)</label>
    <sun-input
      :key="keyInputEmail"
      v-model="value"
      type="text"
      class="mb-2"
      :prevent-enter="true"
      @input="inputEmails"
      @blur="lostFocus"
      @paste="pasteEmails"
    />
    <sun-error-text class="mb-2" :show-error="activeErrors" :text-error="activeErrorMessage"></sun-error-text>
    <div v-if="emailsSelected" class="flex flex-wrap">
      <div
        v-for="(email, index) in emailsSelected"
        :key="index"
        class="px-2 py-1 mr-1 border leading-tight align-middle inline-flex items-center bg-green-300 rounded-full"
      >
        <span class="text-xs mr-2">{{ email }}</span>
        <span class="p-1 cursor-pointer text-gray-700 rounded" @click="removeEmail(index)">
          <close-svg class="w-2" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import defaultProps from '@sunmedia/sun-ui/src/mixins/defaultProps';
import { validateEmail } from '@/utils/validation/validateEmail';
import CONFIG from './config';
import CloseSvg from '@/components/icons/CloseSvg.vue';

export default {
  name: 'MultiSelectEmails',
  components: { CloseSvg },
  mixins: [defaultProps],
  props: {
    hasError: {
      type: Boolean,
      default: () => false,
    },
    errorMessage: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      keyInputEmail: 0,
      value: null,
      emailsSelected: [],
      invalidEmail: CONFIG.invalidEmail,
      invalidEmailMessage: CONFIG.invalidEmailMessage,
    };
  },
  computed: {
    activeErrors() {
      return this.hasError || this.invalidEmail;
    },
    activeErrorMessage() {
      return this.hasError ? this.errorMessage : this.invalidEmailMessage;
    },
  },
  methods: {
    lostFocus({ value }) {
      if (value) {
        this.inputEmails(value, true);
      }
    },
    inputEmails(value, force = false) {
      const valueCleared = this.clearValue(value);

      if (this.hasMoreThanOneEmail(valueCleared)) {
        this.processEmailsArray(valueCleared.split(','));
        return;
      }

      const lastCharacter = value.slice(-1);
      if (this.isSeparate(lastCharacter) || force) {
        if (valueCleared.length === 0) {
          this.isInvalid('Value empty');
          this.value = null;
          this.keyInputEmail += 1;
          return;
        }
        if (this.existsEmail(valueCleared)) {
          this.isInvalid('Email is already');
          return;
        }
        if (validateEmail(valueCleared)) {
          this.addEmail(valueCleared);
          this.resetValidate();
        } else {
          this.value = value.slice(0, -1);
          this.keyInputEmail += 1;
          this.invalidEmail = true;
        }
      }
    },
    addEmail(email) {
      this.emailsSelected.push(email);
      this.value = null;
      this.keyInputEmail += 1;
      this.$emit('change', this.emailsSelected);
    },
    removeEmail(index) {
      this.emailsSelected.splice(index, 1);
      this.$emit('change', this.emailsSelected);
    },
    existsEmail(email) {
      return this.emailsSelected.includes(email);
    },
    clearValue(value) {
      return value.trim().replace(/(^,|,$|\s+)/g, '');
    },
    hasMoreThanOneEmail(value) {
      const matches = value.match(/,/g);

      return matches && matches.length >= 1;
    },
    isSeparate(character) {
      return character === ',' || character === ' ';
    },
    resetValidate() {
      this.invalidEmail = false;
      this.invalidEmailMessage = CONFIG.invalidEmailMessage;
    },
    isInvalid(message) {
      this.invalidEmail = true;
      this.invalidEmailMessage = message ?? CONFIG.invalidEmailMessage;
    },
    pasteEmails(event) {
      const inputText = this.clearValue(event.clipboardData.getData('text'));
      const emails = inputText.split(',');

      if (emails.length >= 1) {
        this.processEmailsArray(emails);
        return;
      }

      this.isInvalid(`Emails not found!`);
    },
    processEmailsArray(emails) {
      const validEmails = [];
      const invalidEmails = [];

      for (const email of emails) {
        if (validateEmail(email)) {
          if (this.existsEmail(email)) {
            this.isInvalid('Emails is already');
            return;
          }
          validEmails.push(email);
        } else {
          invalidEmails.push(email);
        }
      }

      if (invalidEmails.length > 0) {
        this.isInvalid(`Invalid emails: (${invalidEmails.join(',')})`);
        return;
      }

      if (validEmails.length > 0) {
        for (const email of validEmails) {
          this.addEmail(email);
        }
      }

      this.resetValidate();
    },
  },
};
</script>

<style scoped></style>
