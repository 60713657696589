var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "flex flex-col items-start mb-4 sm:mb-0 w-full",
      attrs: { id: _vm.id },
    },
    [
      _c(
        "label",
        { staticClass: "text-gary-700 text-left text-xs sun-label mb-1" },
        [_vm._v("You must separate by comma (,)")]
      ),
      _c("sun-input", {
        key: _vm.keyInputEmail,
        staticClass: "mb-2",
        attrs: { type: "text", "prevent-enter": true },
        on: {
          input: _vm.inputEmails,
          blur: _vm.lostFocus,
          paste: _vm.pasteEmails,
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _c("sun-error-text", {
        staticClass: "mb-2",
        attrs: {
          "show-error": _vm.activeErrors,
          "text-error": _vm.activeErrorMessage,
        },
      }),
      _vm.emailsSelected
        ? _c(
            "div",
            { staticClass: "flex flex-wrap" },
            _vm._l(_vm.emailsSelected, function (email, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass:
                    "px-2 py-1 mr-1 border leading-tight align-middle inline-flex items-center bg-green-300 rounded-full",
                },
                [
                  _c("span", { staticClass: "text-xs mr-2" }, [
                    _vm._v(_vm._s(email)),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "p-1 cursor-pointer text-gray-700 rounded",
                      on: {
                        click: function ($event) {
                          return _vm.removeEmail(index)
                        },
                      },
                    },
                    [_c("close-svg", { staticClass: "w-2" })],
                    1
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }