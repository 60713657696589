var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "w-full h-full fill-current",
      attrs: {
        id: "Layer_1",
        fill: "#000000",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 512 512",
        "xml:space": "preserve",
      },
    },
    [
      _c("g", [
        _c("g", [
          _c("path", {
            attrs: {
              d: "M174.545,302.545H81.455c-6.982,0-11.636,4.655-11.636,11.636s4.655,11.636,11.636,11.636h93.091\n          c6.982,0,11.636-4.655,11.636-11.636S181.527,302.545,174.545,302.545z",
            },
          }),
        ]),
      ]),
      _c("g", [
        _c("g", [
          _c("path", {
            attrs: {
              d: "M139.636,244.364H46.545c-6.982,0-11.636,4.655-11.636,11.636s4.655,11.636,11.636,11.636h93.091\n          c6.982,0,11.636-4.655,11.636-11.636S146.618,244.364,139.636,244.364z",
            },
          }),
        ]),
      ]),
      _c("g", [
        _c("g", [
          _c("path", {
            attrs: {
              d: "M104.727,186.182H11.636C4.655,186.182,0,190.836,0,197.818s4.655,11.636,11.636,11.636h93.091\n          c6.982,0,11.636-4.655,11.636-11.636S111.709,186.182,104.727,186.182z",
            },
          }),
        ]),
      ]),
      _c("g", [
        _c("g", [
          _c("path", {
            attrs: {
              d: "M463.127,155.927c-3.491-4.655-11.636-5.818-16.291-2.327l-123.345,94.255c-12.8,9.309-30.255,9.309-43.055,0\n          L157.091,153.6c-4.655-3.491-12.8-3.491-16.291,2.327c-3.491,4.655-3.491,12.8,2.327,16.291l124.509,94.255\n          c10.473,8.145,23.273,11.636,34.909,11.636s25.6-3.491,34.909-11.636L460.8,172.218\n          C465.455,168.727,466.618,160.582,463.127,155.927z",
            },
          }),
        ]),
      ]),
      _c("g", [
        _c("g", [
          _c("path", {
            attrs: {
              d: "M477.091,104.727H104.727c-6.982,0-11.636,4.655-11.636,11.636S97.745,128,104.727,128h372.364\n          c6.982,0,11.636,4.655,11.636,11.636v232.727c0,6.982-4.655,11.636-11.636,11.636H104.727c-6.982,0-11.636,4.655-11.636,11.636\n          c0,6.982,4.655,11.636,11.636,11.636h372.364c19.782,0,34.909-15.127,34.909-34.909V139.636\n          C512,119.855,496.873,104.727,477.091,104.727z",
            },
          }),
        ]),
      ]),
      _c("g", [
        _c("g", [
          _c("path", {
            attrs: {
              d: "M461.964,340.945l-69.818-69.818c-4.655-4.655-11.636-4.655-16.291,0s-4.655,11.636,0,16.291l69.818,69.818\n          c2.327,2.327,5.818,3.491,8.145,3.491s5.818-1.164,8.146-3.491C466.618,352.582,466.618,345.6,461.964,340.945z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }